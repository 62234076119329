@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@600;700&family=Lato:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}
nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

:root {
    --primary-color: #071932;
    --secondary-color: #15C463;
    --white: #FFFFFF;
    --background: #FAFFFA;
    --text: #8CA0A4;
    margin: 0;
    padding: 0;
}


